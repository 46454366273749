<template>
  <div class="header">
      <h1>Lid toevoegen</h1>
    </div>

  <form @submit.prevent="createLid">
    <div class="inputs">
      <input
          type="text"
          minlength="3"
          required
          v-model="naam"
          placeholder="Naam"
        />
      <input type="number" required placeholder="0" v-model="balance" />
      <input type="submit" value="Lid toevoegen" />
      <div v-show="error" class="error">{{ this.errorMsg }}</div>


    </div>
    
  </form>
</template>

<script>
export default {
  name: "NewMember",
  data() {
    return {
      naam: "",
      balance: null,
      error: null,
      errorMsg: "",
    };
  },
  methods: {
    createLid() {
      this.$store
        .dispatch("createLid", {
          name: this.naam,
          balance: this.balance,
        })
        .then((response) => {
          this.$router.push({ name: "Home" });
        })
        .catch((response) => {
          console.log("ERROR" + response);
          this.error = response;
          this.errorMsg = "Something went wrong";
        });
    },
  },
};
</script>

<style scoped>

.header{
  min-height: 20vh;
  background: linear-gradient(#9AB2C1, #798D9C);
  border-radius: 0 0 30px 30px;
  box-shadow: 9px 8px 17px #00000029;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 55px;
}

.header h1{
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 10px;
}

.inputs{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

input[type=text],
input[type=number]{
  display: block;
  width: 100%;
    height: 100%;
    color: white;
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid white;
  padding: 10px 10px 10px 0px;
  margin-bottom: 15px;
}

input::placeholder{
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 14px;
}

input[type=submit] {
  display: block;
  width: 90%;
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 25px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  margin-top: 40px;
  box-shadow: 0px 3px 6px #1d252894;
}

</style>