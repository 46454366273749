<template>

    <div class="leden-wrapper">
      <input class="query" type="text" :value="query" @input="e => query = e.target.value" placeholder="Search"/>
      <div class="leden">
        <Lid :lid="lid" v-for="(lid, index) in filteredLeden" :key="index" />
      </div>
      
    </div>
</template>

<script>
import Lid from "../components/Lid.vue";

export default {
  name: "Home",
  data() {
    return {
      query: "",
    };
  },
  components: { Lid },
  created() {
    this.$store.dispatch("retrieveLeden");
  },
  computed: {
    updateSearch(arg){
      this.query =arg
    },

    filteredLeden() {
      if(this.query == "-"){
        return this.$store.getters.allLeden.filter((lid) => {
          return lid.balance < 0
        });
      }else{
        return this.$store.getters.allLeden.filter((lid) => {
          return lid.name.toLowerCase().includes(this.query.toLowerCase());
        });
      }
    },
  },
};
</script>

<style scoped>
.leden-wrapper {
  width: 80vw;
  margin: 0 auto;
  padding: 40px 0 0;
}
.leden{
  padding: 20px 0;
}
input.query{
  display: block;
  box-sizing:border-box;
  width: 100%;
  color: white;
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid white;
  padding: 10px 10px 10px 0px;
  margin-bottom: 30px;
}

input::placeholder{
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 14px;
}

/*.home{
  overflow: auto;
  height: calc(100vh - 90px);
}
.leden-wrapper {
  width: 80vw;
  margin: 0 auto;
  padding: 40px 0 0;
}

.leden{
  padding: 20px 0;
}
input.query{
  display: block;
  box-sizing:border-box;
  width: 100%;
  color: white;
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid white;
  padding: 10px 10px 10px 0px;
  margin-bottom: 30px;
}

input::placeholder{
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 14px;
}*/
</style>