import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NewMember from '../views/NewMember.vue'
import Login from '../views/Login.vue'
import LidDetails from '../views/LidDetails.vue'


import Logout from '../components/auth/Logout.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/nieuw-lid',
    name: 'NewMember',
    component: NewMember,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/lid-details/:id',
    name: 'LidDetails',
    component: LidDetails
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
