<template>
  <router-link class="lid" :to="{ name: 'LidDetails', params: { id: lid.id } }">
    <h2>{{ capitalize(lid.name) }}</h2>
    <h3 :class="{ 'negative' : lid.balance < 0}">{{ formatPrice(lid.balance) }}</h3>
  </router-link>
</template>

<script>
export default {
  name: "lid",
  props: ["lid"],

  methods: {
    capitalize(word) {
      return word.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    },
    formatPrice(value) {
        return new Intl.NumberFormat('de-NL', { style: 'currency', currency: 'EUR' }).format(value).replace("-", "-").replace("€", "€ ");;
    },
  }
};
</script>

<style  scoped>
.negative{
  color: #ff2c2c;
}

.lid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  background-color: #374248;
  height: 66px;
  margin-bottom: 30px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 19px;
  padding: 0px 25px ;
  box-shadow: 0px 3px 6px #1d252894;
}



</style>