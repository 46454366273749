<template>
  <div v-if="currentLid" class="home">
    <div class="lid-info">
      <h1>{{ capitalize(this.currentLid.name) }}</h1>
      <h2 :class="{ 'negative' : this.currentLid.balance < 0}">{{formatPrice(this.currentLid.balance)}}</h2>
    </div>
    <div class="controls">
        <div class="drinks">
            <ul>
              <li>
                <button v-on:click="reduceBalance(-1.5)">
                  <div class="button-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="31.549" height="46.152" viewBox="0 0 31.549 46.152">
                    <g id="hop" transform="translate(-81.001 0)">
                      <path id="Path_1" data-name="Path 1" d="M202.336,4.421a4.055,4.055,0,0,1,4.062,0,14.99,14.99,0,0,1,1.324.861,12.179,12.179,0,0,1,1.76-.472A10.679,10.679,0,0,0,204.991.153a1.348,1.348,0,0,0-1.249,0,10.679,10.679,0,0,0-4.491,4.657,12.18,12.18,0,0,1,1.76.472A14.987,14.987,0,0,1,202.336,4.421Z" transform="translate(-107.591)" fill="#20c3b0"/>
                      <path id="Path_2" data-name="Path 2" d="M125.258,84.066a18.837,18.837,0,0,1,2.153-2.971,29.077,29.077,0,0,0-5.059-.141A1.353,1.353,0,0,0,121,82.306v1.713C124.276,84.019,124.513,84,125.258,84.066Z" transform="translate(-36.394 -73.653)" fill="#20c3b0"/>
                      <path id="Path_3" data-name="Path 3" d="M322.034,84.066c.737-.062.882-.047,4.257-.047V82.306a1.353,1.353,0,0,0-1.352-1.352,29.081,29.081,0,0,0-5.059.141A18.962,18.962,0,0,1,322.034,84.066Z" transform="translate(-217.348 -73.653)" fill="#20c3b0"/>
                      <path id="Path_4" data-name="Path 4" d="M206.879,78.057q.322.187.635.389a13.081,13.081,0,0,1,2.4-1.04,12.815,12.815,0,0,0-4.391-4.219,1.351,1.351,0,0,0-1.354,0,12.815,12.815,0,0,0-4.391,4.219,13.074,13.074,0,0,1,2.4,1.04q.312-.2.635-.389A4.055,4.055,0,0,1,206.879,78.057Z" transform="translate(-108.073 -66.424)" fill="#20c3b0"/>
                      <path id="Path_5" data-name="Path 5" d="M102.465,151.081l.323-.622A17.372,17.372,0,0,1,106.3,145.8a10.293,10.293,0,0,0-3.985-.8H98.344a1.353,1.353,0,0,0-1.352,1.352c0,4.323-.007,4.181.02,4.6A40.241,40.241,0,0,1,102.465,151.081Z" transform="translate(-14.549 -131.933)" fill="#20c3b0"/>
                      <path id="Path_6" data-name="Path 6" d="M311.791,145.8a17.374,17.374,0,0,1,3.508,4.659l.324.622a39.977,39.977,0,0,1,5.452-.129c.027-.411.02-.226.02-4.6A1.353,1.353,0,0,0,319.742,145h-3.966A10.3,10.3,0,0,0,311.791,145.8Z" transform="translate(-209.987 -131.931)" fill="#20c3b0"/>
                      <path id="Path_7" data-name="Path 7" d="M194.049,162.912a14.972,14.972,0,0,1,6.138-3.926,14.3,14.3,0,0,0-5.461-5.8,1.351,1.351,0,0,0-1.354,0,14.3,14.3,0,0,0-5.461,5.8A14.964,14.964,0,0,1,194.049,162.912Z" transform="translate(-97.273 -139.213)" fill="#20c3b0"/>
                      <path id="Path_8" data-name="Path 8" d="M224,464.247a1.352,1.352,0,0,0,1.352,1.352h3.065a1.352,1.352,0,0,0,1.352-1.352V461H224Z" transform="translate(-130.11 -419.448)" fill="#20c3b0"/>
                      <path id="Path_9" data-name="Path 9" d="M111.2,241h-3.606a12.178,12.178,0,0,0-10.817,6.6A12.178,12.178,0,0,0,85.959,241H82.353A1.353,1.353,0,0,0,81,242.354v3.606A12.182,12.182,0,0,0,93.17,258.128h7.211A12.182,12.182,0,0,0,112.55,245.96v-3.606A1.353,1.353,0,0,0,111.2,241Z" transform="translate(0 -219.279)" fill="#20c3b0"/>
                    </g>
                  </svg>
                  <p>Bier</p>
                  </div>
                  
                </button>
              </li>
              <li>
                <button v-on:click="reduceBalance(-1)">
                  <div class="button-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.164" height="46.353" viewBox="0 0 28.164 46.353">
                      <g id="soda" transform="translate(-100.455)">
                        <g id="Group_2" data-name="Group 2" transform="translate(100.455 9.975)">
                          <g id="Group_1" data-name="Group 1">
                            <path id="Path_10" data-name="Path 10" d="M125.1,110.177H103.976l-3.52,3.52v20.608a17.519,17.519,0,0,1,2.132-1.881c3.153-2.375,7.288-4.059,11.286-5.688,7.583-3.09,14.746-6.008,14.746-13.039Z" transform="translate(-100.456 -110.177)" fill="#20c3b0"/>
                          </g>
                        </g>
                        <g id="Group_4" data-name="Group 4" transform="translate(100.455 22.225)">
                          <g id="Group_3" data-name="Group 3">
                            <path id="Path_11" data-name="Path 11" d="M126.487,247.37c-3.153,2.375-7.288,4.059-11.286,5.688-7.583,3.09-14.746,6.008-14.746,13.039l3.521,3.52H125.1l3.52-3.52V245.489A17.516,17.516,0,0,1,126.487,247.37Z" transform="translate(-100.455 -245.489)" fill="#20c3b0"/>
                          </g>
                        </g>
                        <g id="Group_6" data-name="Group 6" transform="translate(105.736)">
                          <g id="Group_5" data-name="Group 5">
                            <path id="Path_12" data-name="Path 12" d="M164.066,0a5.287,5.287,0,0,0-5.281,5.281V6.454h3.52V5.281a1.76,1.76,0,1,1,3.52,0V6.454h3.52V5.281A5.287,5.287,0,0,0,164.066,0Z" transform="translate(-158.785)" fill="#20c3b0"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  <p>Frisdrank</p>
                  </div>
                  
                </button>
              </li>
              <li>
                <button v-on:click="reduceBalance(-0.5)">
                  <div class="button-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="43.316" height="24.399" viewBox="0 0 43.316 24.399">
                      <g id="snack" transform="translate(0 -111.801)">
                        <g id="Group_8" data-name="Group 8" transform="translate(0 123.91)">
                          <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
                            <path id="Path_13" data-name="Path 13" d="M2.862,258.881A9.606,9.606,0,0,0,0,264.679a8.787,8.787,0,0,0,.041.951s.226,2.1,2.951,1.481h.014c2.673-.26,4.52-3.153,6.633-7.738.355-.77.7-1.553,1.072-2.383.3-.676.606-1.363.921-2.054A13.785,13.785,0,0,0,2.862,258.881Z" transform="translate(0 -254.935)" fill="#20c3b0"/>
                          </g>
                        </g>
                        <g id="Group_10" data-name="Group 10" transform="translate(9.468 111.801)">
                          <g id="Group_9" data-name="Group 9" transform="translate(0 0)">
                            <path id="Path_14" data-name="Path 14" d="M131.184,111.8c-8.775,0-12.226,7.757-15.563,15.258a50.958,50.958,0,0,1-3.706,7.307,27.338,27.338,0,0,1,7.508-1.629,30.984,30.984,0,0,1,7.855,1.018,25.389,25.389,0,0,0,6.361.866c2.025,0,12.124-.527,12.124-10.925C145.763,116.581,138.224,111.8,131.184,111.8Zm-1.871,15.276a1.347,1.347,0,1,1,.4-.954A1.356,1.356,0,0,1,129.313,127.077Zm6.353-5.086a1.349,1.349,0,1,1,.395-.954A1.359,1.359,0,0,1,135.666,121.99Z" transform="translate(-111.915 -111.801)" fill="#20c3b0"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  <p>Chips</p>
                  </div>
                  
                </button>
              </li>
              <li>
                <button v-on:click="reduceBalance(-2)">
                  <div class="button-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35.164" height="42.871" viewBox="0 0 35.164 42.871">
                      <g id="apple" transform="translate(-46.022)">
                        <path id="Path_24" data-name="Path 24" d="M57.68,150.952c-6.381-.037-11.658-13.062-11.658-19.695,0-10.835,8.128-13.207,11.261-13.207a12.958,12.958,0,0,1,4.249,1.045,9.5,9.5,0,0,0,2.426.7,7.55,7.55,0,0,0,1.74-.564,13.949,13.949,0,0,1,5.246-1.268h.012a10.752,10.752,0,0,1,9,4.544l.656.986-.944.713c-1.349,1.018-3.811,2.875-3.811,6.553a7.4,7.4,0,0,0,4.127,6.838c.591.356,1.2.722,1.2,1.525,0,.524-4.18,11.77-10.25,11.77a8.243,8.243,0,0,1-3.461-.84,7.019,7.019,0,0,0-3.081-.742,7.925,7.925,0,0,0-2.44.66,11.95,11.95,0,0,1-4.232.987Z" transform="translate(0 -108.081)" fill="#20c3b0"/>
                        <path id="Path_25" data-name="Path 25" d="M263.208,0c.158,5.7-3.918,9.655-7.99,9.407C254.547,4.858,259.29,0,263.208,0Z" transform="translate(-191.621)" fill="#20c3b0"/>
                      </g>
                    </svg>

                  <p>Strongbow</p>
                  </div>
                  
                </button>
              </li>
            </ul>
        </div>
        <div class="extra">
            <div class="extra-wrapper">
              <div class="add-funds-wrapper">
              <input type="number" placeholder="Add funds" v-model="addFundsAmount">
              
              <div class="button-container">
                <button id="add-funds"  v-on:click="reduceBalance(addFundsAmount)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.595" height="29.812" viewBox="0 0 18.595 29.812">
                    <g id="up-arrow" transform="translate(-64.546 0)">
                      <g id="Group_12" data-name="Group 12" transform="translate(64.546 0)">
                        <g id="Group_11" data-name="Group 11" transform="translate(0)">
                          <path id="Path_15" data-name="Path 15" d="M82.923,8.657,74.3.213a.59.59,0,0,0-.908,0L64.764,8.657a.681.681,0,0,0,.454,1.18H69.3V20.778a1.044,1.044,0,0,0,.908.863h7.264c.363,0,.454-.454.454-.863V9.837h4.54a.681.681,0,0,0,.454-1.18Z" transform="translate(-64.546 0)" fill="#fff"/>
                          <path id="Path_16" data-name="Path 16" d="M129.855,264.722h-7.264a.681.681,0,1,0,0,1.362h7.264a.681.681,0,1,0,0-1.362Z" transform="translate(-116.926 -241.72)" fill="#fff"/>
                          <path id="Path_17" data-name="Path 17" d="M129.855,296.069h-7.264a.681.681,0,0,0,0,1.362h7.264a.681.681,0,1,0,0-1.362Z" transform="translate(-116.926 -270.343)" fill="#fff"/>
                          <path id="Path_18" data-name="Path 18" d="M129.855,327.415h-7.264a.681.681,0,0,0,0,1.362h7.264a.681.681,0,1,0,0-1.362Z" transform="translate(-116.926 -298.965)" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                  </button>
              </div>

             
              </div>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LidDetails",
  data() {
    return {
      currentLid: null,
      addFundsAmount: null
    };
  },
  async mounted() {
    

    this.currentLid = await this.$store.getters.allLeden.filter((lid) => {
      return lid.id == this.$route.params.id;
    })[0];
    if(!this.currentLid){
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    formatPrice(value) {
        return new Intl.NumberFormat('de-NL', { style: 'currency', currency: 'EUR' }).format(value).replace("-", "-").replace("€", "€ ");;
    },
    capitalize(word) {
      return word.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    },

    reduceBalance(amount) {
      console.log(amount)
      if(amount){
        console.log("bla")
        this.$store
          .dispatch("reduceBalance", {
            id: this.currentLid.id,
            amount: amount
          })
          .then((response) => {
            this.currentLid = response.data
            this.addFundsAmount = null
            //this.$router.push({ name: "Home" });
          })
          .catch((response) => {
            this.error = response;
            console.log("error " + response)
          });
      }
      
    },


  }
};
</script>

<style scoped>
.lid-info{
  min-height: calc(var(--vh, 1vh) * 17);
    margin-bottom: 3vh;
  background: linear-gradient(#9AB2C1, #798D9C);
  border-radius: 0 0 30px 30px;
  box-shadow: 9px 8px 17px #00000029;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.lid-info h1{
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 10px;
}
.lid-info h2{
  font-size: 33px;
  line-height: 53px;
  font-weight: 200;
}

.controls{
  width: 80vw;
  margin: 0 auto;
}

.drinks button{
  width: 100%;
  border: none;
  padding: 0px 10px;
  background-color: #374248;
  height: 66px;
  margin-bottom: 30px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 19px;
  padding: 0px 25px ;
  box-shadow: 0px 3px 6px #1d252894;
}

.drinks button:active{
  background-color: #3f4b52;
}
.drinks button .button-content{
  display: flex;
  align-items: center;
  width: 100%;
}

.drinks button .button-content svg{
  margin-right: 35px;
  width: 13%;
}

.extra{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 14px;
}
.extra .extra-wrapper{
  width: 100%;
  
  border: none;
  background-color: #374248;
  height: 66px;
  margin-bottom: 30px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 19px;
  box-shadow: 0px 3px 6px #1d252894;
}

.extra .extra-wrapper .add-funds-wrapper{
  display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 0px 15px;
}

.extra .extra-wrapper .add-funds-wrapper .button-container{
  width: 13%;
}

.extra .extra-wrapper .add-funds-wrapper input{
  display: block;
    color: white;
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid white;
  padding: 10px 10px 10px 0px;
  width: 65%;
}

.extra .extra-wrapper .add-funds-wrapper input::placeholder{
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 14px;
}

.extra button#add-funds{
  background-color: #20C3B0;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #1d252894;
}

.extra button#add-funds:active{
  background-color: #2ea396;
}


.lid-info h2.negative{
  color: #d80000;
  font-weight: 400;
}

</style>