<template>
  <div class="form-wrap">
    <div class="logo">
      <h1>KAJ</h1>
      <h2>Drankkaarten</h2>
    </div>
    <form class="login" @submit.prevent="signIn">
      
      <div class="inputs">
        <div class="input">
          <input type="text" placeholder="Email" v-model="email" />
        </div>
        <div class="input">
          <input type="password" placeholder="Password" v-model="password" />
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
      <input type="submit" value="Login" />
    </form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: null,
      errorMsg: "",
    };
  },
  methods: {
    signIn() {
      this.$store
        .dispatch("retrieveToken", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.$router.push({ name: "Home" });
        })
        .catch((response) => {
          this.error = response;
          this.errorMsg = "Wrong credentials " +response;
        });
    },
  },
};
</script>

<style scoped>
.form-wrap{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1, h2{
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 50px;
  color: white;
}
h1{
  line-height: 66px;
}
h2{
  font-size: 30px;
  line-height: 40px;
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 110px;
  margin-bottom: 200px;
}

.login{
  position: relative;
  top:  -155px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
}

.login .inputs{
  width: 100%;
}

.login input[type=text],
.login input[type=password]{
  display: block;
  width: 100%;
    height: 100%;
    color: white;
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid white;
  padding: 10px 10px 10px 0px;
  margin-bottom: 15px;
  
}

.login input[type=submit] {
  display: block;
  width: 90%;
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 25px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  margin-top: 40px;
  box-shadow: 0px 3px 6px #1d252894;
}

.login input::placeholder{
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  font-size: 14px;
  text-shadow: 0px 3px 6px #1d252894;
}
</style>