import { createStore } from 'vuex'
import axios from 'axios'

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://kaj-api.onrender.com/api/'
}else{
  axios.defaults.baseURL = 'http://127.0.0.1:8888/api/'
}
  

export default createStore({
  state: {
    token: localStorage.getItem('access_token') || null,
    leden: []
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    allLeden(state) {
      return state.leden;
    }
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token
    },
    logout(state) {
      state.token = null
    },

    retrieveLeden(state, leden) {
      state.leden = leden
    },
    addLid(state, lid) {
      state.todos.push(lid)
    },
    updateLid(state, lid) {
      const index = state.leden.findIndex(item => item.id == lid.id)
      state.leden.splice(index, 1, lid)
    },
  },
  actions: {
    logout(context) {
      localStorage.removeItem('access_token')
      context.commit('logout')
    },
    reduceBalance(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        axios.put(`lid/${data.id}/balance`, {
          amount: data.amount,
        })
          .then(response => {

            context.commit('updateLid', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        axios.post('auth/login', {
          email: credentials.email,
          password: credentials.password,
        })
          .then(response => {

            const token = response.data.access_token

            localStorage.setItem('access_token', token)
            context.commit('retrieveToken', token)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    retrieveLeden(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
      axios.get('lid')
        .then(response => {
          context.commit('retrieveLeden', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    createLid(context, lid) {
      return new Promise((resolve, reject) => {
        axios.post('lid', {
          name: lid.name,
          balance: lid.balance,
        })
          .then(response => {
            //context.commit('addLid', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  modules: {
  }
})
