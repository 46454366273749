<template>
  <header v-show="loggedIn">
    <nav>
      <ul>
        <li>
          <router-link class="header" :to="{ name: 'Home' }">
            <svg xmlns="http://www.w3.org/2000/svg" width="33.536" height="33.535" viewBox="0 0 33.536 33.535">
              <g id="house" transform="translate(0 0)">
                <path id="Path_19" data-name="Path 19" d="M26.549,33.535H6.987a2.8,2.8,0,0,1-2.795-2.795V18.165H1.4a1.382,1.382,0,0,1-1.4-1.4,1.42,1.42,0,0,1,.414-1L16.279.2a.7.7,0,0,1,.978,0l15.86,15.566a1.424,1.424,0,0,1,.419,1,1.382,1.382,0,0,1-1.4,1.4H29.344V30.741A2.8,2.8,0,0,1,26.549,33.535ZM16.768,1.678,1.4,16.763l3.493,0a.7.7,0,0,1,.7.7V30.741a1.4,1.4,0,0,0,1.4,1.4H26.549a1.4,1.4,0,0,0,1.4-1.4V17.466a.7.7,0,0,1,.7-.7h3.493a.016.016,0,0,0,0-.008Z" transform="translate(0 0)" fill="#fff"/>
              </g>
            </svg>
          </router-link>
        </li>
        <li><router-link class="header" :to="{ name: 'NewMember' }">
          <svg id="plus" xmlns="http://www.w3.org/2000/svg" width="36.055" height="36.055" viewBox="0 0 36.055 36.055">
            <path id="Path_20" data-name="Path 20" d="M18.028,0A18.028,18.028,0,1,0,36.055,18.028,18.048,18.048,0,0,0,18.028,0Zm0,34.668A16.641,16.641,0,1,1,34.668,18.028,16.66,16.66,0,0,1,18.028,34.668Z" fill="#fff"/>
            <path id="Path_21" data-name="Path 21" d="M30.528,21.32H22.554V13.693a.693.693,0,1,0-1.387,0V21.32H13.193a.693.693,0,0,0,0,1.387h7.974v8.32a.693.693,0,1,0,1.387,0v-8.32h7.974a.693.693,0,0,0,0-1.387Z" transform="translate(-3.833 -3.986)" fill="#fff"/>
          </svg>
        </router-link></li>
        <li v-if="!loggedIn"><router-link :to="{ name: 'Logout' }">Temp Logout</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
import home from "../assets/icons/house.svg";
import plus from "../assets/icons/plus.svg";

export default {
  components: {
    home,
    plus,
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<style scoped>
header {
  box-shadow: 0px -1px 6px #1d252894;
  height: 90px;
  position: fixed;
  width: 100vw;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #374148, #283136);
}
nav{
  width: 100%;
}

nav ul {
  display: flex;
  padding: 20px 0;
  justify-content: space-around;
}

nav ul li{
  display: block;
}

.icon {
          width: 14px;
          height: 14px;
          height: auto;
          margin-right: 8px;
        }
</style>